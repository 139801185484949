import { useState, useEffect } from "react";

interface IUseIntersectionObserver {
	threshold?: any
	root?: any
	rootMargin?: any
}


export const useOnScreen = (ref: any, rootMargin = '0px') => {
	// State and setter for storing whether element is visible
	const [isIntersecting, setIntersecting] = useState(false);
	useEffect(() => {
		const refValue = ref
		const observer = new IntersectionObserver(
			([entry]) => {
				// Update our state when observer callback fires				
				setIntersecting(entry.isIntersecting);
			},
			{
				rootMargin,
				threshold: 0,

			}
		);
		if (refValue.current) {
			observer.observe(refValue.current);
		}
		return () => {
			observer.unobserve(refValue.current);
		};
	}, [ref, rootMargin])

	return isIntersecting;
}