import { EJobActionTypes, IJobState } from 'models'
import * as _ from 'lodash'
import { initialState } from '../initial'

interface ILocations {
	id: string
	location: {
		id: string
		name: string
		region: string
	}
}

interface ISkills {
	id: string
	title: string
}

export default (state: IJobState, action: any): IJobState => {
	switch (action.type) {
		case EJobActionTypes.initState: {
			return {
				...initialState.job,
				id: action.initState.id,
				jobCompany: action.initState.jobCompany,
			}
		}
		case EJobActionTypes.resetState: {
			return { ...initialState.job }
		}
		case EJobActionTypes.setJob: {
			const {
				id,
				benefits,
				company,
				contractType,
				description,
				featuredImage,
				videoUrl,
				jobType,
				locationType,
				locations,
				salary,
				seniorityLevel,
				skills,
				slug,
				teams,
				title,
				expiryDate,
				teamLeader,
				applyJobExternalUrl,
			} = action.dataJob
			const parsedLocations =
				locations &&
				locations.map((item: ILocations) => {
					return {
						value: item.id,
						label: item.location.name,
					}
				})
			const parsedSkills =
				skills &&
				skills.map((item: ISkills) => {
					return {
						value: item.id,
						label: item.title,
					}
				})
			return {
				...state,
				id,
				benefits,
				jobCompany: company,
				title,
				applyJobExternalUrl,
				locations: parsedLocations,
				jobDescription: description,
				salary: {
					...state.salary,
					amountMin: salary ? salary.amountMin : '',
					amountMax: salary ? salary.amountMax : '',
					currency: salary ? salary.currency : '',
					rate: salary ? salary.rate : '',
				},
				contractType,
				jobTypeLoc: locationType,
				featuredImage: featuredImage
					? {
							id: featuredImage.split('-')[0],
							filename: featuredImage,
					  }
					: undefined,
				featuredVideo: videoUrl,
				skills: parsedSkills,
				seniorityLevel,
				expiryDate,
				slug,
				jobType,
				team:
					teams.length === 0
						? {
								value: '',
								label: 'Alocat intregii companii',
								team: null,
						  }
						: {
								label: teams[0].name,
								value: teams[0].id,
								team: {
									// this field is for team details section from preview job page
									id: teams[0].id,
									name: teams[0].name,
									slug: teams[0].slug,
									hasCover: teams[0].hasCover,
									coverPath: teams[0].coverPath,
								},
						  },
				teamMembers:
					teams.length === 0
						? []
						: teams[0].members.length > 0
						? teams[0].members.map((item: any) => {
								return {
									value: item.id,
									label: `${item.user.profile.firstName} ${item.user.profile.lastName}`,
								}
						  })
						: [],
				teamLeader:
					!teamLeader && teams.length === 0
						? undefined
						: teams[0].members.length > 0
						? teams[0].members
								.filter((item: any) => item.id === teamLeader)
								.map((item: any) => {
									return {
										value: item.id,
										label: `${item.user.profile.firstName} ${item.user.profile.lastName}`,
									}
								})
						: [],
				// On backend side teams are returned as an array but in frontend the user can add one team. Adding multiple teams on frontend is a future feature.
			}
		}

		case EJobActionTypes.setTeamMembers: {
			return { ...state, teamMembers: action.teamMembers }
		}
		case EJobActionTypes.setTeamLeader: {
			return { ...state, teamLeader: action.teamLeader }
		}
		case EJobActionTypes.setCompanyJob: {
			return {
				...state,
				jobCompany: action.jobCompany,
			}
		}
		case EJobActionTypes.setTitle:
			return {
				...state,
				title: action.title,
				error: {
					...state.error,
					title: _.isEmpty(action.title),
				},
			}
		case EJobActionTypes.setApplyJobExternalUrl:
			return {
				...state,
				applyJobExternalUrl: action.applyJobExternalUrl,
				error: {
					...state.error,
				},
			}
		case EJobActionTypes.setJobDescription:
			return {
				...state,
				jobDescription: action.jobDescription,
				error: {
					...state.error,
					jobDescription: _.isEmpty(action.jobDescription),
				},
			}

		case EJobActionTypes.setExpirationDate:
			return {
				...state,
				expiryDate: action.expiryDate,
				error: {
					...state.error,
					expiryDate: _.isEmpty(action.expiryDate),
				},
			}
		case EJobActionTypes.setError: {
			return {
				...state,
				error: {
					...state.error,
					...action.errors,
				},
			}
		}
		case EJobActionTypes.setSkills:
			return {
				...state,
				skills: action.skills,
				error: {
					...state.error,
					skills: _.isEmpty(action.skills),
				},
			}
		case EJobActionTypes.setBenefits:
			return {
				...state,
				benefits: action.benefits,
				error: {
					...state.error,
					benefits: _.isEmpty(action.benefits),
				},
			}
		case EJobActionTypes.setLocations:
			return {
				...state,
				locations: action.locations,
				error: {
					...state.error,
					locations: _.isEmpty(action.locations),
				},
			}
		case EJobActionTypes.setStartSalary:
			return {
				...state,
				salary: {
					...state.salary,
					amountMin: action.startSalary,
				},
				error: {
					...state.error,
					salary: {
						...state.error.salary,
						amountMin: !action.startSalary,
					},
				},
			}
		case EJobActionTypes.setEndSalary: {
			return {
				...state,
				salary: {
					...state.salary,
					amountMax: action.endSalary,
				},
				error: {
					...state.error,
					salary: {
						...state.error.salary,
						amountMax: !action.endSalary,
					},
				},
			}
		}
		case EJobActionTypes.setCurrency:
			return {
				...state,
				salary: {
					...state.salary,
					currency: action.currency,
				},
				error: {
					...state.error,
					salary: {
						...state.error.salary,
						currency: _.isEmpty(action.currency),
					},
				},
			}
		case EJobActionTypes.setSalaryRate:
			return {
				...state,
				salary: {
					...state.salary,
					rate: action.rate,
				},
				error: {
					...state.error,
					salary: {
						...state.error.salary,
						rate: _.isEmpty(action.rate),
					},
				},
			}
		case EJobActionTypes.setContractType:
			return {
				...state,
				contractType: action.contractType,
				error: {
					...state.error,
					contractType: _.isEmpty(action.contractType),
				},
			}
		case EJobActionTypes.setJobType:
			return {
				...state,
				jobType: action.jobType,
				error: {
					...state.error,
					jobType: _.isEmpty(action.jobType),
				},
			}

		case EJobActionTypes.setJobTypeByLoc:
			const currentData = state.jobTypeLoc
			if (currentData.includes(action.jobTypeLoc)) {
				_.remove(currentData, function (e) {
					return e === action.jobTypeLoc
				})
			} else
				return {
					...state,
					jobTypeLoc: [...state.jobTypeLoc, action.jobTypeLoc],
					error: {
						...state.error,
						jobTypeLoc: _.isEmpty(action.jobTypeLoc),
					},
				}
		case EJobActionTypes.setSeniorityLevel:
			return {
				...state,
				seniorityLevel: action.seniorityLevel,
				error: {
					...state.error,
					seniorityLevel: _.isEmpty(action.seniorityLevel),
				},
			}
		case EJobActionTypes.setTeam:
			return {
				...state,
				team: action.team,
			}

		case EJobActionTypes.setFeaturedImage:
			return {
				...state,
				featuredImage: action.featuredImage,
				featuredVideo: '',
			}

		case EJobActionTypes.deleteFeaturedImg:
			return {
				...state,
				featuredImage: undefined,
			}
		case EJobActionTypes.setSearchForDefaultCompanyOffices:
			return {
				...state,
				searchForDefaultCompanyOffices: action.value,
				defaultCompanyOffices: action.data,
			}
		case EJobActionTypes.setSearchForDefaultCompanyTeams:
			return {
				...state,
				searchForDefaultCompanyTeams: action.value,
				defaultCompanyTeams: action.data,
			}
		case EJobActionTypes.setFeaturedVideo:
			return {
				...state,
				featuredVideo: action.featuredVideo,
				featuredImage: undefined,
			}
		case EJobActionTypes.clearMedia:
			return {
				...state,
				featuredVideo: '',
				featuredImage: undefined,
			}
		default:
			return state
	}
}
