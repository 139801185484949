import React, { memo, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { useTranslation } from 'react-i18next'
import { Theme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useLocation, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import Divider from '@material-ui/core/Divider'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Avatar from '@material-ui/core/Avatar'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'

import {
	LogoutMutation,
	StoreValue,
	ExportStudentsKit,
	ExportChillPackRecipients,
} from 'store'
import generalStyles from '../styles'
import styles from './styles'
import {
	TMenuContentProps,
	EAppStateActionTypes,
	ECompanyActionTypes,
} from 'models'
import { EnumUserRoles, combineStyles } from 'utils'
import mixpanel from 'mixpanel-browser'

const combinedStyles: any = combineStyles(generalStyles, styles)
const useStyles = makeStyles(combinedStyles)

const MenuContentView: React.FunctionComponent<TMenuContentProps> = ({
	closeMenu,
	closeProfileMenu,
	type,
	storeUserUid,
	storeUserId,
	storeUserIg,
	firebase: { instance: firebaseApp },
	storeDispatch,
	userCompany,
	storeUserCompanyRoles,
}) => {
	const client = useApolloClient()
	const history = useHistory()
	const location = useLocation()
	const classes = useStyles()
	const { t, i18n } = useTranslation()
	const [logoutMutationHandle] = useMutation(LogoutMutation)
	const theme: Theme = useTheme()
	const [openSubMenu, setOpenSubMenu] = React.useState('myCompany')
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const showAddCompanyBtn = !userCompany || storeUserIg

	const handleClick = useCallback(
		value => () => {
			setOpenSubMenu(openSubMenu => (value === openSubMenu ? '' : value))
		},
		[],
	)

	const checkForRigthCompanyRoles = useMemo(() => {
		let roles =
			storeUserCompanyRoles &&
			storeUserCompanyRoles.filter((item: any) => {
				return item.roles.includes(
					EnumUserRoles.JOB_PUBLISHER.toString(),
				) // if the user has at least JOB_PUBLISHER role
			})
		return roles
	}, [storeUserCompanyRoles])

	const logout = async (event: React.MouseEvent | React.ChangeEvent<any>) => {
		await logoutMutationHandle()
		localStorage.clear()
		mixpanel.reset()
		await firebaseApp
			.auth()
			.signInAnonymously()
			.then((firebaseUser: any) => {
				storeDispatch({
					type: EAppStateActionTypes.resetUser,
					firebaseUser,
				})
			})
			.catch((err: any) => {
				console.error(err)
			})
	}

	const toggleLanguage = (languageCode: string) => () => {
		i18n.changeLanguage(languageCode)
		history.push(`/${languageCode}${location.pathname.substr(3)}`)
	}

	const handleBurgerMenuRedirect = useCallback(
		(
				section:
					| 'about_us'
					| 'blog'
					| 'for_companies'
					| 'terms_and_conditions'
					| 'privacy'
					| 'contact'
					| 'faq'
					| 'jobs',
			) =>
			() => {
				switch (section) {
					case 'about_us':
						window.open('https://www.user.cv30.co/team', '_blank')
						break
					case 'blog':
					case 'for_companies':
						window.open('https://employers.cv30.co', '_blank')
						break
					case 'terms_and_conditions':
						history.push(`/${i18n.language}/terms_and_conditions`)
						break
					case 'privacy':
						history.push(`/${i18n.language}/privacy`)
						break
					case 'jobs':
						history.push(`/${i18n.language}/jobs`)
						break
					case 'faq':
						window.open('https://www.user.cv30.co', '_blank')
						break
					case 'contact':
						window.open(
							' https://www.user.cv30.co/contact-us',
							'_blank',
						)
						break
					default:
						break
				}
				closeMenu()
			},
		[history, i18n, closeMenu],
	)
	const handleUserMenuRedirect = useCallback(
		(
			event,
			section:
				| 'my_profile'
				| 'my_settings'
				| 'applied_jobs'
				| 'add_company'
				| 'company_profile'
				| 'company_teams'
				| 'company_add_a_new_job'
				| 'company_jobs'
				| 'company_settings'
				| 'manage_companies_profile',
			companySlug?: string,
			companyName?: string,
		) => {
			closeProfileMenu(event)
			switch (section) {
				case 'my_profile':
					history.push({
						pathname: `/${i18n.language}/people/${storeUserUid}`,
					})
					break
				case 'my_settings':
					history.push({
						pathname: `/${i18n.language}/people/${storeUserUid}/settings`,
					})
					break
				case 'add_company':
					history.push({
						pathname: `/${i18n.language}/create-company`,
						state: { storeUserId },
					})
					break
				case 'company_profile':
					history.push({
						pathname: `/${i18n.language}/employers/${companySlug}`,
					})
					break
				case 'manage_companies_profile':
					history.push({
						pathname: `/${i18n.language}/employers/${companySlug}`,
					})
					break
				case 'company_teams':
					history.push({
						pathname: `/${i18n.language}/employers/${companySlug}/teams`,
					})
					break
				case 'company_add_a_new_job':
					history.push({
						pathname: `/${i18n.language}/${companySlug}/jobs/create`,
						state: {
							add: true,
						},
					})
					break
				case 'company_settings':
					history.push({
						pathname: `/${i18n.language}/employers/${companySlug}/company-settings`,
					})
					break
				case 'company_jobs':
					storeDispatch({
						type: ECompanyActionTypes.setCompanySettingsTab,
						companySettingsTab: 'Jobs',
					})
					history.push({
						pathname: `/${i18n.language}/employers/${companySlug}/company-settings`,
						state: { companyName },
					})
					break

				default:
					break
			}
			closeMenu()
		},
		[
			closeMenu,
			storeUserId,
			storeDispatch,
			closeProfileMenu,
			history,
			i18n.language,
			storeUserUid,
		],
	)

	const handleExportStudentsKitData = useCallback(async () => {
		interface IStudentsKit {
			id: string
			firstName: string
			lastName: string
			email: string
			university: string
			faculty: string
			specialization: string
			hasAccount: string
			location: string
			createdAt: Date
			updatedAt: Date
			scanDate: string
			scanningAgentEmail: string
			// scanningAgentName?: string
		}

		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		const fileExtension = '.xlsx'

		const exportToCSV = (csvData: any, fileName: string) => {
			const ws = XLSX.utils.json_to_sheet(csvData)
			const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
			const excelBuffer = XLSX.write(wb, {
				bookType: 'xlsx',
				type: 'array',
			})
			const data = new Blob([excelBuffer], { type: fileType })

			FileSaver.saveAs(data, fileName + fileExtension)
		}

		await client
			.query({
				query: ExportStudentsKit,
			})
			.then((data: { data: { exportStudents: IStudentsKit[] } }) => {
				const dataForCSV = data.data.exportStudents.map(
					(item: IStudentsKit) => {
						const {
							firstName,
							lastName,
							email,
							university,
							faculty,
							specialization,
							location,
							hasAccount,
							scanDate,
							scanningAgentEmail,
						} = item

						return {
							Prenume: firstName,
							Nume: lastName,
							Email: email,
							Universitate: university,
							Facultate: faculty,
							Specializare: specialization,
							'Are cont?': hasAccount ? 'da' : 'nu',
							'Oras de domiciliu': location,
							'Data scanarii codului QR': scanDate
								? moment(scanDate).format('DD-MM-YYYY')
								: '-',
							'Operator scanare': scanningAgentEmail
								? scanningAgentEmail
								: '-',
						}
					},
				)

				exportToCSV(dataForCSV, 'StudentsKit')
			})
			.catch(err => {
				console.log(err)
			})
	}, [client])

	const handleChillPackRecipientsData = useCallback(async () => {
		interface IChillPackRecipient {
			id: string
			email: string
			chillPackRecipientClaim: string | null
			createdAt: string
			recipientLastName: string
			recipientFirstName: string
			companyName: string
		}

		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		const fileExtension = '.xlsx'

		const exportToCSV = (csvData: any, fileName: string) => {
			const ws = XLSX.utils.json_to_sheet(csvData)
			const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
			const excelBuffer = XLSX.write(wb, {
				bookType: 'xlsx',
				type: 'array',
			})
			const data = new Blob([excelBuffer], { type: fileType })

			FileSaver.saveAs(data, fileName + fileExtension)
		}

		await client
			.query({
				query: ExportChillPackRecipients,
			})
			.then(
				(data: {
					data: { exportChillPackRecipients: IChillPackRecipient[] }
				}) => {
					const dataForCSV = data.data.exportChillPackRecipients.map(
						(item: IChillPackRecipient) => {
							const {
								email,
								chillPackRecipientClaim,
								createdAt,
								recipientLastName,
								recipientFirstName,
								companyName,
							} = item

							return {
								recipientLastName,
								recipientFirstName,
								email,
								companyName,
								chillPackRecipientClaim,
								createdAt,
							}
						},
					)

					exportToCSV(dataForCSV, 'Chill_Pack')
				},
			)
			.catch(err => {
				console.error(err)
			})
	}, [client])

	return (
		<>
			{(type === 'profile' || type === 'full') && storeUserUid && (
				<List
					component="nav"
					classes={{ root: classes.rightMenuContentItems }}
				>
					<ListItem
						button
						onClick={event =>
							handleUserMenuRedirect(event, 'my_profile')
						}
					>
						<ListItemText primary={t('My profile')} />
					</ListItem>

					<ListItem
						button
						onClick={event =>
							handleUserMenuRedirect(event, 'my_settings')
						}
					>
						<ListItemText primary={t('My Settings')} />
					</ListItem>
					{/* <ListItem button>
						<ListItemText primary={t('Applied jobs')} />
					</ListItem> */}
					{showAddCompanyBtn && (
						<ListItem
							button
							onClick={event =>
								handleUserMenuRedirect(event, 'add_company')
							}
						>
							<ListItemText primary={t('Add company')} />
						</ListItem>
					)}

					{userCompany && (
						<div>
							<div
								style={
									!isSmDown
										? { background: 'white' }
										: { paddingLeft: '20px' }
								}
							>
								<ListItem
									button
									onClick={handleClick('myCompany')}
								>
									<ListItemText
										className={classNames(
											classes.categoryHeader,
										)}
										primary={t('My Company')}
									/>
									{openSubMenu === 'myCompany' ? (
										<ExpandLess />
									) : (
										<ExpandMore />
									)}
								</ListItem>

								<Collapse
									in={openSubMenu === 'myCompany'}
									timeout="auto"
									unmountOnExit
								>
									<List component="div" disablePadding>
										<ListItem
											className={
												classes.rightMenuCompanyLabel
											}
										>
											<ListItemText
												primary={userCompany.name}
											/>
										</ListItem>
										<ListItem
											button
											onClick={event =>
												handleUserMenuRedirect(
													event,
													'company_profile',
													userCompany.slug,
												)
											}
										>
											<ListItemText
												primary={t('Profile')}
											/>
										</ListItem>
										<ListItem
											button
											onClick={event =>
												handleUserMenuRedirect(
													event,
													'company_teams',
													userCompany.slug,
												)
											}
										>
											<ListItemText
												primary={t('Teams')}
											/>
										</ListItem>
										<ListItem
											button
											onClick={event =>
												handleUserMenuRedirect(
													event,
													'company_add_a_new_job',
													userCompany.slug,
												)
											}
										>
											<ListItemText
												primary={t('Add a new job')}
											/>
										</ListItem>
										<ListItem
											button
											onClick={event =>
												handleUserMenuRedirect(
													event,
													'company_jobs',
													userCompany.slug,
													userCompany.name,
												)
											}
										>
											<ListItemText primary={t('Jobs')} />
										</ListItem>
										<ListItem
											button
											onClick={event =>
												handleUserMenuRedirect(
													event,
													'company_settings',
													userCompany.slug,
												)
											}
										>
											<ListItemText
												primary={t('Settings')}
											/>
										</ListItem>
									</List>
								</Collapse>
							</div>
						</div>
					)}

					{checkForRigthCompanyRoles.length > 0 && (
						<>
							<ListSubheader
								classes={{ root: classes.subtitle }}
								component="div"
								id="nested-list-subheader"
							>
								{t('Manage the companies')}
							</ListSubheader>
							{storeUserCompanyRoles.map(
								(item: any, index: number) => {
									return (
										<React.Fragment
											key={`company_roles_${index}`}
										>
											<ListItem
												button
												onClick={handleClick(
													`company_${index}`,
												)}
											>
												<Avatar
													classes={{
														root: classes.avatar,
													}}
												>
													<FiberManualRecordIcon fontSize="small" />
												</Avatar>
												<ListItemText
													className={
														classes.rightMenuCompanyLabel
													}
													primary={item.company.name}
												/>
												{openSubMenu ===
												`company_${index}` ? (
													<ExpandLess />
												) : (
													<ExpandMore />
												)}
											</ListItem>
											<Collapse
												in={
													openSubMenu ===
													`company_${index}`
												}
												timeout="auto"
												unmountOnExit
											>
												<List
													component="div"
													disablePadding
													classes={{
														root: classes.subMenuList,
													}}
												>
													<ListItem
														button
														onClick={event =>
															handleUserMenuRedirect(
																event,
																'company_profile',
																item.company
																	.slug,
															)
														}
													>
														<ListItemText
															primary={t(
																'Profile',
															)}
														/>
													</ListItem>
													<ListItem
														button
														onClick={event =>
															handleUserMenuRedirect(
																event,
																'company_teams',
																item.company
																	.slug,
															)
														}
													>
														<ListItemText
															primary={t('Teams')}
														/>
													</ListItem>
													<ListItem
														button
														onClick={event =>
															handleUserMenuRedirect(
																event,
																'company_add_a_new_job',
																item.company
																	.slug,
															)
														}
													>
														<ListItemText
															primary={t(
																'Add a new job',
															)}
														/>
													</ListItem>
													<ListItem
														button
														onClick={event =>
															handleUserMenuRedirect(
																event,
																'company_jobs',
																item.company
																	.slug,
																item.company
																	.name,
															)
														}
													>
														<ListItemText
															primary={t('Jobs')}
														/>
													</ListItem>
													<ListItem
														button
														onClick={event =>
															handleUserMenuRedirect(
																event,
																'company_settings',
																item.company
																	.slug,
															)
														}
													>
														<ListItemText
															primary={t(
																'Settings',
															)}
														/>
													</ListItem>
												</List>
											</Collapse>
										</React.Fragment>
									)
								},
							)}
						</>
					)}

					{storeUserIg && (
						<>
							<ListItem
								button
								onClick={handleExportStudentsKitData}
							>
								<ListItemText
									primary={t('Export Students Kit')}
								/>
							</ListItem>
							<ListItem
								button
								onClick={handleChillPackRecipientsData}
							>
								<ListItemText
									primary={t('Export ChillPack Recipients')}
								/>
							</ListItem>
						</>
					)}
					<ListItem button onClick={logout}>
						<ListItemText primary={t('Log out')} />
					</ListItem>
				</List>
			)}
			{(type === 'site' || type === 'full') && (
				<>
					{type === 'full' && <Divider light />}
					<MenuList className={classes.rightMenuContentItems}>
						<MenuItem onClick={handleBurgerMenuRedirect('jobs')}>
							{t('Jobs')}
						</MenuItem>
						<MenuItem
							onClick={handleBurgerMenuRedirect('about_us')}
						>
							{t('About us')}
						</MenuItem>
						{/* <MenuItem onClick={handleBurgerMenuRedirect('blog')}>
							{t('Blog')}
						</MenuItem> */}
						<MenuItem
							onClick={handleBurgerMenuRedirect('for_companies')}
						>
							{t('For companies')}
						</MenuItem>
						<MenuItem
							onClick={handleBurgerMenuRedirect(
								'terms_and_conditions',
							)}
						>
							{t('Terms & conditions')}
						</MenuItem>
						<MenuItem onClick={handleBurgerMenuRedirect('privacy')}>
							{t('Privacy')}
						</MenuItem>
						<MenuItem onClick={handleBurgerMenuRedirect('contact')}>
							{t('Contact')}
						</MenuItem>
						<MenuItem onClick={handleBurgerMenuRedirect('faq')}>
							{t('FAQ')}
						</MenuItem>
						<MenuItem className={classes.rightMenuCompanyLabel}>
							<Button
								variant="outlined"
								size="small"
								className={classNames(classes.languageButton, {
									[classes.languageActive]:
										i18n.language === 'en',
								})}
								onClick={toggleLanguage('en')}
							>
								EN
							</Button>
							<div className={classes.languageDivider}>|</div>
							<Button
								variant="outlined"
								size="small"
								className={classNames(classes.languageButton, {
									[classes.languageActive]:
										i18n.language === 'ro',
								})}
								onClick={toggleLanguage('ro')}
							>
								RO
							</Button>
						</MenuItem>
					</MenuList>
				</>
			)}
		</>
	)
}

const MenuContent = memo((props: TMenuContentProps) => (
	<StoreValue
		keys={[
			'user.uid|storeUserUid',
			'user.id|storeUserId',
			'user._ig|storeUserIg',
			'user.companyRoles|storeUserCompanyRoles',
			'firebase',
			'storeDispatch',
		]}
	>
		<MenuContentView {...props} />
	</StoreValue>
))

export default MenuContent
